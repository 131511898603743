export const BRAND_CODES = {
  adyen: 'adyen',
  afterpay: 'afterpay',
  applepay: 'applepay',
  atome: 'atome',
  cod: 'cod',
  doku: 'doku',
  dragonpay: 'dragonpay',
  giftcard: 'gift_card',
  googlepay: 'googlepay',
  molpay: 'fpx',
  paypal: 'paypal',
  promptpay: 'kbank'
}

export const CART_ATTRIBUTES = [
  'packageOption',
  'availablePaymentMethods',
  'availablePackagingOptions',
  'amountPayable',
  'costSummary',
  'state',
  'shippingAddressId'
]

export const ENCRYPTED_CARD_NUMBER = 'encryptedCardNumber'
export const ENCRYPTED_EXPIRY_DATE = 'encryptedExpiryDate'
export const ENCRYPTED_EXPIRY_MONTH = 'encryptedExpiryMonth'
export const ENCRYPTED_EXPIRY_YEAR = 'encryptedExpiryYear'
export const ENCRYPTED_SECURITY_CODE = 'encryptedSecurityCode'

export const GENERAL_ADYEN_ERROR_CODES = ['error.va.gen.01', 'error.va.gen.02']

export const OBJECT_STORES = {
  cart: 'cart',
  order: 'order'
}

// From Api::V2m5::OrdersController#payment_params
export const PERMITTED_CREDIT_CARD_PARAMS = [
  'save_card',
  'token_id',
  'encrypted_card',
  'calling_code',
  'phone_number',
  'encrypted_card_number',
  'encrypted_expiry_month',
  'encrypted_expiry_year',
  'encrypted_security_code',
  'name_on_card',
  'last_four_digits',
  'bin'
]

export const PLATFORMS = {
  WEB: 'web_desktop',
  WEB_MOBILE: 'web_mobile'
}

export const SELF_COLLECTION_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  UNSUPPORTED: 'UNSUPPORTED'
}

export const SELF_COLLECT_STATION_PROVIDERS = {
  SINGPOST: 'singpostPopStation',
  AU_POST: 'auPost'
}

export const SELF_COLLECT_STATION_SERIALIZER_TYPE = 'selfCollectStations'

export const UNSUPPORTED_PAYPAL_CURRENCIES = ['IDR', 'AED']

export const USER_TYPES = {
  GUEST: 'Guest',
  SIGNED_IN: 'Authenticated'
}

export const DOKU_PAYMENTS = [
  'doku_bca_va',
  'doku_bni_va',
  'doku_cimb_va',
  'doku_danamon_va',
  'doku_mandiri_va',
  'doku_permata_lite_atm'
]

export const DELIVERY_METHODS = {
  cnc: 'click_and_collect',
  regular: 'regular'
}
